<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { fetchLinkBackAnnotation } from '@/api'
import { scrollTo } from 'epmc-patterns/helpers'
import { getCookie, setCookie } from '@/helpers/cookie'
import { registerMatomoEvent } from '@/helpers/matomo'
import Annotations from '@/helpers/article/annotation'
import { ContentSection } from 'epmc-patterns/components/v2'
import Abstract from './sections/Abstract'
import FiguresAndTables from './sections/FiguresAndTables'
import FreeFullText from './sections/FreeFullText'
import FullTextLinks from './sections/FullTextLinks'
import PriorityNumber from './sections/PriorityNumber'
import Classifier from './sections/Classifier'
import Impact from './sections/Impact'
import Funding from './sections/Funding'
import References from './sections/References'
import SimilarArticles from './sections/SimilarArticles'
import DataProviderCollection from './sections/DataProviderCollection'
import ReviewsAndEvaluations from './sections/ReviewsAndEvaluations'

const getLinkBackId = () => {
  let id = ''

  const hash = window.location.hash
  if (hash) {
    const h = hash.replace('#', '')
    const parts = h.split('-')
    if (
      parts.length === 2 &&
      parts[0].length &&
      parts[1] &&
      parts[1].length === 32
    ) {
      id = h
    }
  }

  return id
}

export default {
  components: {
    ContentSection,
    Abstract,
    FiguresAndTables,
    FreeFullText,
    PriorityNumber,
    Classifier,
    FullTextLinks,
    Impact,
    Funding,
    References,
    SimilarArticles,
    DataProviderCollection,
    ReviewsAndEvaluations,
  },
  props: {
    sourceUrl: {
      type: String,
      default: '',
    },
    idUrl: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('article/sections', ['sections']),
    ...mapState('article/abstract', ['abstract']),
  },
  async mounted() {
    if (window.location.hash) {
      const id = window.location.hash.substring(1)
      if (this.sections.findIndex((sec) => sec.id === id && sec.render) >= 0) {
        this.changeSectionVariable({ id, visible: true })
        if (
          this.sections
            .filter((sec) => sec.render)
            .findIndex((sec) => sec.id === id) >= 0
        ) {
          scrollTo('#' + id, '#' + id + ' div:first-child')
        }
      } else {
        const linkBackId = getLinkBackId()
        if (linkBackId) {
          const { source, id, pmcid } = this.abstract

          // can be optimized later
          const bindings = await this.getLinkbackAnnotation(
            pmcid ? 'PMC' : source,
            pmcid ? pmcid.substring(3) : id,
            linkBackId
          )
          if (pmcid && (!bindings || !bindings.length)) {
            await this.getLinkbackAnnotation(source, id, linkBackId)
          }
        } else {
          this.changeSectionVariable({ id: 'free-full-text', visible: true })
          const scrollItem = id.match(/\./g)
            ? `#${id.replace(/\./g, '\\.')} > *:first-child`
            : '#' + id
          scrollTo(scrollItem, '#fulltextcontent')
        }
      }
    } else {
      if (
        getCookie('old_redirect') === 'redirect' &&
        (this.sourceUrl.toUpperCase() === 'PMC' ||
          this.sourceUrl.toUpperCase() === 'NBK' ||
          (this.sourceUrl.toUpperCase() === 'CTX' && this.abstract.hasFulltext))
      ) {
        this.changeSectionVariable({ id: 'free-full-text', visible: true })
        setCookie('old_redirect', '', -1, '.europepmc.org')
        //scrollTo('#free-full-text', '#free-full-text')
      }
    }
  },
  methods: {
    ...mapMutations('article/sections', ['changeSectionVariable']),
    ...mapActions('article/textHighlight', ['searchAndHighlight']),
    scrollInside(section) {
      if (section.id !== 'free-full-text') {
        const fulltextMenuItem = document.querySelector(
          '#full-text-menu li.highlighted'
        )
        if (fulltextMenuItem) {
          fulltextMenuItem.classList.remove('highlighted')
        }
      }
      if (!section.inFrame) {
        this.sections
          .filter((sec) => sec.inFrame)
          .map((s) => (s.inFrame = false))
        section.inFrame = true
      }
    },
    async trackOpen(title) {
      registerMatomoEvent('Article', title, 'Opened section')
    },
    async getLinkbackAnnotation(source, id, linkBackId) {
      return fetchLinkBackAnnotation(source, id, linkBackId).then(
        async (response) => {
          if (response) {
            const id = 0
            const { bindings } = response.results
            if (bindings.length) {
              const str = Annotations.processText(bindings[0].exact.value)
              const prefix = Annotations.processText(bindings[0].prefix.value)
              const postfix = Annotations.processText(bindings[0].postfix.value)
              const options = {
                searchOptions: {
                  prefix,
                  postfix,
                },
                highlightOptions: {
                  color: '#c0c0c0',
                  highlightClass: 'linkback-highlight',
                },
              }

              const highlightIndex = await this.searchAndHighlight({
                highlighter: 'fulltextHighlighter',
                id,
                str,
                options,
              })
              if (highlightIndex === -1) {
                this.searchAndHighlight({
                  highlighter: 'abstractHighlighter',
                  id,
                  str,
                  options,
                })
              }
            }
            return Promise.resolve(bindings)
          }
        }
      )
    },
  },
}
</script>
<template>
  <div>
    <content-section
      v-for="section in sections.filter((sect) => sect.render)"
      :key="section.id"
      :section="section"
      @scrollInside="scrollInside"
      @opened="trackOpen"
    >
      <component
        :is="section.component"
        :data-tags="section.dataTags"
        :section-id="section.id"
      />
    </content-section>
    <div
      v-if="!sections.find((s) => s.id === 'figures-and-tables').render"
      itemprop="image"
      itemscope
      itemtype="http://schema.org/ImageObject"
    >
      <meta
        itemprop="url"
        content="http://europepmc.org/template/images/epmc-logo-single.png"
      />
    </div>
  </div>
</template>
<style lang="scss">
.linkback-highlight {
  cursor: pointer;
  background-color: rgba(248, 231, 28, 0.4);
}
a.lang-sw {
  text-decoration: underline !important;
}
a.lang-sw.selected {
  color: #666 !important;
  font-weight: 600;
}
</style>
